
















import { Component, Vue, Prop } from 'vue-property-decorator'
import GeneralSettings from '@/components/GeneralSettings.vue'
import Triggers from '@/components/Triggers.vue'
import { VMixRemote } from '@/app'

@Component({
  components: {
    GeneralSettings,
    Triggers
  }
})
export default class Settings extends Vue {
  @Prop() vMixRemote!: VMixRemote

  data () {
    return {
      tab: null
    }
  }
}
